.react-tel-input .special-label {
  display: none !important;
}
.react-tel-input .form-control {
  padding: 0.775rem 1rem 0.775rem 60px !important;
}

form {
  max-width: 450px;
  margin: 0 auto;
}
@media (orientation: landscape) {
  form {
    max-width: 400px;
    margin: 0 auto;
  }
}
.pagination {
  .page-item {
    cursor: pointer;
  }
}
